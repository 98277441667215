@media print {
  @import 'variables';
  @page {
    size: a4;   /* auto is the initial value */
    margin: 20mm;  /* this affects the margin in the printer settings */
  }
  .page-pensumlister {
    // Disable href in Chrome
    height: 100%;
    a[href]::after {
      content: " ";
    }

    main.container {
      margin-top: 10px !important;
    }

    // Alter page title.
    h1 {
      position: relative;
      visibility: hidden;

      &::after {
        -webkit-print-color-adjust: exact;
        color: rgb(126, 164, 180) !important;
        content: "Din pensumliste";
        display: inline-block;
        font-size: calc(16px + 3vw);
        font-weight: 500;
        margin-bottom: 1vw;
        position: absolute;
        text-align: center;
        text-transform: none;
        top: 0;
        left: 0;
        visibility: visible;
        width: 100%;
      }
    }

    // Show uni and course info.
    .show-print {
      display: inline-block;
      font-size: 24px;
      font-weight: 300;
      margin-top: 20px;
      text-align: center;
      width: 100%;

      ul {
        display: inline-block;
        font-size: calc(16px + .5vw);
        padding-left: 0;

        li {
          float: left;
          list-style-type: none;
          padding-left: 10px;

          &:not(:last-child):after {
            content: ",";
          }

          &:first-child {
            padding-left: 0;
          }
        }
      }
    }

    // Hide fields
    footer,
    header,
    select,
    [type=checkbox]:not(:checked) + label::before,
    [type=checkbox]:checked + label::before,
    [type=checkbox]:checked + label::after,
    .form-actions,
    .group-left,
    .intro p,
    .recommended-button,
    .subheader {
      display: none !important;
    }

    .pane-syllabus {
      .col-md-5,
      .col-md-6,
      .col-md-7 {
        float: none;
        padding: 0;
        position: relative;
        width: 100%;
      }

      .col-md-6 > div {
        margin-top: 0;
      }

      .product-teaser:not(:last-child) {
        border-bottom: 1px solid rgb(126, 164, 180);
      }

      .group-right {
        flex: 0 0 100%;

        .field-label,
        .field-isbn {
          padding-top: 3px;
        }
      }

      .syllabus-page {
        width: 100%;
        margin: 0;
      }

      .syllabus-list {
        padding-top: 0;

        .product-teaser {
          page-break-inside: avoid;
        }

        .node-product-display {
          padding-left: 0;
          position: inherit;
          top: 0;
        }

        border: none;
        margin: 0;

        legend,
        .fieldset {
          border: 0;
          border-radius: 0;
          padding: 0;
        }

        h2, h5 {
          font-size: 18px;
          font-weight: 500;
        }

        .field-author, .field-author a {
          font-size: 15px;
          font-weight: 400;
          margin-top: 2px;
        }

        .supplementary {
          .field-ab-prices {
            .price {
              &.strike {
                -webkit-print-color-adjust: exact;
                color: #688a97 !important;
              }
            }
          }
        }

        .field-ab-prices {
          right: 0;
          bottom: 17px;

          .price {
            font-size: 18px;
            font-weight: 400;
            text-align: right;
          }

          .strike {
            -webkit-print-color-adjust: exact;
            color: $dark-grey !important;

            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

    // Show course comment first.
    .fieldset-wrapper {
      .course-comment {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        page-break-inside: avoid;
      }
    }

    // Course titles
    legend {
      -webkit-print-color-adjust: exact;
      background-color: rgb(126, 164, 180) !important;
      margin-bottom: 4px;
      padding: 4px 10px !important;

      span {
        color: $white !important;
      }
    }

    .supplementary {
      h4 {
        border-top: 1px solid rgb(126, 164, 180);
        font-size: 20px;
        margin: 0;
        padding-left: 0;
        padding-top: 10px;
      }

      .product-teaser,
      .field-label,
      .field-isbn,
      .field-ab-prices .price,
      .field-author,
      .field-author a,
      .field-ab-prices .price .strike,
      h4,
      h2 a {
        -webkit-print-color-adjust: exact;
        color: #688a97 !important;
      }
    }
  }
}
