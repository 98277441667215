@media print {
  @page {
    size: a4;
    /* auto is the initial value */
    margin: 20mm;
    /* this affects the margin in the printer settings */
  }
  .page-pensumlister {
    height: 100%;
  }
  .page-pensumlister a[href]::after {
    content: " ";
  }
  .page-pensumlister main.container {
    margin-top: 10px !important;
  }
  .page-pensumlister h1 {
    position: relative;
    visibility: hidden;
  }
  .page-pensumlister h1::after {
    -webkit-print-color-adjust: exact;
    color: #7ea4b4 !important;
    content: "Din pensumliste";
    display: inline-block;
    font-size: calc(16px + 3vw);
    font-weight: 500;
    margin-bottom: 1vw;
    position: absolute;
    text-align: center;
    text-transform: none;
    top: 0;
    left: 0;
    visibility: visible;
    width: 100%;
  }
  .page-pensumlister .show-print {
    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }
  .page-pensumlister .show-print ul {
    display: inline-block;
    font-size: calc(16px + .5vw);
    padding-left: 0;
  }
  .page-pensumlister .show-print ul li {
    float: left;
    list-style-type: none;
    padding-left: 10px;
  }
  .page-pensumlister .show-print ul li:not(:last-child):after {
    content: ",";
  }
  .page-pensumlister .show-print ul li:first-child {
    padding-left: 0;
  }
  .page-pensumlister footer,
  .page-pensumlister header,
  .page-pensumlister select,
  .page-pensumlister [type=checkbox]:not(:checked) + label::before,
  .page-pensumlister [type=checkbox]:checked + label::before,
  .page-pensumlister [type=checkbox]:checked + label::after,
  .page-pensumlister .form-actions,
  .page-pensumlister .group-left,
  .page-pensumlister .intro p,
  .page-pensumlister .recommended-button,
  .page-pensumlister .subheader {
    display: none !important;
  }
  .page-pensumlister .pane-syllabus .col-md-5,
  .page-pensumlister .pane-syllabus .col-md-6,
  .page-pensumlister .pane-syllabus .col-md-7 {
    float: none;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .page-pensumlister .pane-syllabus .col-md-6 > div {
    margin-top: 0;
  }
  .page-pensumlister .pane-syllabus .product-teaser:not(:last-child) {
    border-bottom: 1px solid #7ea4b4;
  }
  .page-pensumlister .pane-syllabus .group-right {
    flex: 0 0 100%;
  }
  .page-pensumlister .pane-syllabus .group-right .field-label,
  .page-pensumlister .pane-syllabus .group-right .field-isbn {
    padding-top: 3px;
  }
  .page-pensumlister .pane-syllabus .syllabus-page {
    width: 100%;
    margin: 0;
  }
  .page-pensumlister .pane-syllabus .syllabus-list {
    padding-top: 0;
    border: none;
    margin: 0;
  }
  .page-pensumlister .pane-syllabus .syllabus-list .product-teaser {
    page-break-inside: avoid;
  }
  .page-pensumlister .pane-syllabus .syllabus-list .node-product-display {
    padding-left: 0;
    position: inherit;
    top: 0;
  }
  .page-pensumlister .pane-syllabus .syllabus-list legend,
  .page-pensumlister .pane-syllabus .syllabus-list .fieldset {
    border: 0;
    border-radius: 0;
    padding: 0;
  }
  .page-pensumlister .pane-syllabus .syllabus-list h2, .page-pensumlister .pane-syllabus .syllabus-list h5 {
    font-size: 18px;
    font-weight: 500;
  }
  .page-pensumlister .pane-syllabus .syllabus-list .field-author, .page-pensumlister .pane-syllabus .syllabus-list .field-author a {
    font-size: 15px;
    font-weight: 400;
    margin-top: 2px;
  }
  .page-pensumlister .pane-syllabus .syllabus-list .supplementary .field-ab-prices .price.strike {
    -webkit-print-color-adjust: exact;
    color: #688a97 !important;
  }
  .page-pensumlister .pane-syllabus .syllabus-list .field-ab-prices {
    right: 0;
    bottom: 17px;
  }
  .page-pensumlister .pane-syllabus .syllabus-list .field-ab-prices .price {
    font-size: 18px;
    font-weight: 400;
    text-align: right;
  }
  .page-pensumlister .pane-syllabus .syllabus-list .field-ab-prices .strike {
    -webkit-print-color-adjust: exact;
    color: #979797 !important;
    font-size: 14px;
    font-weight: 500;
  }
  .page-pensumlister .fieldset-wrapper .course-comment {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    page-break-inside: avoid;
  }
  .page-pensumlister legend {
    -webkit-print-color-adjust: exact;
    background-color: #7ea4b4 !important;
    margin-bottom: 4px;
    padding: 4px 10px !important;
  }
  .page-pensumlister legend span {
    color: #fff !important;
  }
  .page-pensumlister .supplementary h4 {
    border-top: 1px solid #7ea4b4;
    font-size: 20px;
    margin: 0;
    padding-left: 0;
    padding-top: 10px;
  }
  .page-pensumlister .supplementary .product-teaser,
  .page-pensumlister .supplementary .field-label,
  .page-pensumlister .supplementary .field-isbn,
  .page-pensumlister .supplementary .field-ab-prices .price,
  .page-pensumlister .supplementary .field-author,
  .page-pensumlister .supplementary .field-author a,
  .page-pensumlister .supplementary .field-ab-prices .price .strike,
  .page-pensumlister .supplementary h4,
  .page-pensumlister .supplementary h2 a {
    -webkit-print-color-adjust: exact;
    color: #688a97 !important;
  }
}
